// @ts-ignore
import { watch, ref, computed, Ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { PaginatedResponseMeta } from '@/models';

export function useRouteChange() {
  const route = useRoute();
  const routeRef = computed(() => ({
    path: route.path,
    query: route.query
  }));
  return (callback) => {
    const unWatchRoute = watch(routeRef, async (to, from) => {
      if (from.path === to.path && from.query !== to.query) {
        await callback();
      } else {
        unWatchRoute();
      }
    });
  };
}

export const usePagination = (): Ref<PaginatedResponseMeta | null> => ref<PaginatedResponseMeta|null>(null);

export const useChangePage = () => {
  const router = useRouter();
  const route = useRoute();
  return async (pageNumber: number) => {
    await router.replace({
      path: route.path,
      query: {
        ...route.query,
        page: pageNumber.toString()
      }
    });
  };
};

export const useUpdateRouteQuery = () => {
  const router = useRouter();
  const route = useRoute();
  return async <T = { [key: string]: any }>(newQueryParams: T) => {
    await router.replace({
      path: route.path,
      query: {
        ...route.query,
        ...newQueryParams
      }
    });
  };
};
